.companies-section1 {
  padding: 170px 0 170px 0;
  background-color: #f7f9fb;

  .btn {
    color: $primary;

    &:hover {
      color: #ffffff;
    }
  }

  .chat-button {
    width: 110px;
    height: 86px;
    position: absolute;
    right: 50px;
    bottom: -80px;
    border: 1px solid #9ea2b1;
    display: flex;
    justify-content: center;
    background: #ffffff;
    border-radius: 30px;

    @media (max-width: 991px) {
      width: 100px;
      height: 80px;
      border-radius: 26px;
      bottom: -100px;
    }
  }
}

.companies-section2 {
  height: 940px;
  background-image: url("../images/companies/companies-section2-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;

  @media (max-width: 992px) {
    height: 800px;
    background-image: url("../images/companies/companies-section2-background-mobile.svg") !important;
  }

  .companies-section2-content {
    width: 630px;
    position: absolute;
    top: 240px;
    left: 50%;

    @media (max-width: 1200px) {
      left: 42%;
    }

    @media (max-width: 992px) {
      top: 120px;
      left: 0;
    }

    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}
