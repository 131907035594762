.classForContainer {
  position: fixed;
  right: 100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 85, 212, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;
  outline: none;

  &:hover {
    background-color: $primary !important;
    svg {
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: $primary !important;
  }
}
