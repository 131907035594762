.cookiealert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
    z-index: 999999999999999999;
    opacity: 0;
    visibility: hidden;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    color: #ecf0f1;
    background: #212327;

    p{
        font-size: 15px;
        padding: 0px 20px;

        @media (max-width: 1200px) {
            font-size: 13px;
            padding: 0px 10px;
        }
    }

    button {
        width: 150px !important;
        height: 40px !important;
        font-size: 15px !important;
        font-weight: 400 !important;

        border-radius: 4px;

        border-color: #ecf0f1;
        background-color: #ecf0f1;
        color: #212327;
        border: none;

        @media (max-width: 1200px) {
            font-size: 13px !important;
        }
    }
    .btn-outline {
        background: transparent !important;
        border: 1px solid #ecf0f1 !important;
        color: #ecf0f1 !important;
    }
}

.cookiealert.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
    transition-delay: 1000ms;
}

.cookiealert a {
    text-decoration: underline;
}
