.topsection {
  background-image: url("../images/dashboard/top_background.gif");
  height: 1080px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  .content {
    max-width: 645px;
    padding-top: 80px;
  }

  .chat-button {
    width: 110px;
    height: 86px;
    position: absolute;
    right: 60px;
    bottom: 70px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    border-radius: 30px;

    @media (max-width: 991px) {
      width: 100px;
      height: 80px;
      border-radius: 26px;
    }

  }
}

.dashboard-section1 {
  .dashboard-section1-content-column {
    padding: 100px 0;
    @media (max-width: 768px) {
      padding: 60px 0;
    }
  }
}

.dashboard-section2 {
  background-image: url("../images/dashboard/for_company.png") !important;
  padding: 130px 0px 170px 0px;
}

.dashboard-section3 {
  background-image: url("../images/dashboard/Group\ 1000001025\ \(3\).png");

  .card {
    height: auto;
    width: auto;
    border-radius: 10px;
    border: 2px solid #4e4697;
  }
}

.dashboard-section4 {
  padding-top: 250px;
  padding-bottom: 150px;

  background-image: url("../images/dashboard/forcompany_background2.png");
  background-repeat: no-repeat;
  border-radius: 0px;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
}

.dashboard-section5 {
  padding: 220px 0px 200px 0px;
  background-image: url("../images/dashboard/whoweare_background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;

  @media (max-width: 1200px) {
    background-image: url("../images/dashboard/for_company.png") !important;
  }

  .dashboard-section5-content1 {
    width: 435px;

    @media (max-width: 992px) {
      width: 580px !important;
    }
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }

  .dashboard-section5-content2 {
    width: 630px;
    
    @media (max-width: 600px) {
      width: 100% !important;
    }
  }
}

.dashboard-section6 {
  padding: 75px 0px 110px 0px;
  background-image: url("../images/dashboard/faq_background.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .card {
    border: 2px solid #888888 !important;
    border-radius: 32px !important;
  }
}

.dashboard-section7 {
  padding: 150px 0px;
  background-image: url("../images/dashboard/contact_background.png");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}


.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(#{10 * 3});
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(#{(2 + 0.5) * 2 * 2});
}

.carousel__slide-item {
  display: inline-block;
  height: 2h;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 2;
}

.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 50%;
  overflow: hidden;
  position: relative;
  width: 50%;

  img {
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    width: 100%;
  }

  &::after {
    align-items: center;
    background: rgba(black, 0.5);
    color: white;
    content: "read more";
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 100%;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    img {
      transform: scale(1.3);
    }
  }
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;

  h4 {
    margin: 0.7rem 0 0;
    text-transform: uppercase;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0.7rem 0 0;
  }
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;

  transform: translateY(-50%);

  &--prev {
    left: -10rem;
  }

  &--next {
    right: -10rem;
  }
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 3rem;
  padding: 3px;
  width: 3rem;
  z-index: 10;

  &--left {
    transform: rotate(135deg);
  }

  &--right {
    transform: rotate(-45deg);
  }
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);

  .dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 2rem;

    &.active {
      background: black;
    }
  }
}
.rec {
  display: flex;
  justify-content: center;
}

.kdCslO {
  margin-top: 5rem !important;
}
.krmNah {
  background-color: #6a40bc !important;
  border: none !important;
}
