.login-properties-bg {
    background-image: url("../images/login/login_bg.svg");
    width: 1920;
    height: 1080px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.properties-container {
    background: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    margin: auto;

    .object-wrapper {
        text-align: center;

        img {
            
        }

        .sub-message {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ffff;
        }

      

       
        .login-properties-button {
            width: 100%;
            height: 100%;
            background: linear-gradient(373.75deg, #01DFB8 -20.6%, #885EFF 84.37%);
            border-radius: 30px;
            border: none;
            color: white;
            font-family: 'Inter' !important;
            font-style: normal !important;
            font-weight: 500;
            font-size: 20px !important;

            display: flex;
            justify-content: center;
            align-items: center;
            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .mic-icon {
            background: url('../images/login/mic-icon.png');
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            padding-left: 2rem;
            position: relative;
            left: 2rem;
        }

        .arrow-icon {
            background: url('../images/login/button-icon.png');
            padding-right: 3rem;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
        }

        .file-icon {
            background: url(../images/login/file-icon.png);
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            padding-left: 2rem;
            position: relative;
            left: 2rem;
        }

        .button {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            padding-left: 3.5rem;
            display: flex;
            text-align: initial;
        }
    }

    .title {
        font-family: 'Inter' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 44.471px !important;
        line-height: 54px !important;
        color: #ffff;
    }


}

.arrow-icon-topbar {
    background: url('../images/login/button-icon.png');
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
}

.topbar-homepage {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;

}

.login-card {
    min-height: 523px;
    max-height: 523px;
    width: 100%;
    border-radius: 20px;
}

.login-form-container {
    padding-left: '5rem';
    position: absolute;
    padding-top: 5rem;



}

.login-form-title {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 27px;
    padding-bottom: 0.5rem;

    display: flex;
    align-items: center;

    color: #333333;
}

.login-form-subtitle {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16.1278px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #757575;
}

.login-form-input {

    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    padding: 0px 13.8238px !important;
    gap: 13.82px !important;
    width: 405px !important;
    height: 52px !important;
    border: 1.15198px solid #DEDEDE !important;
    border-radius: 50px !important;
}

.login-submit-button {
    height: 50px;
    width: 405px;
    border-radius: 50px;
    background: #01dfb8;
    border: 2px solid #01dfb8;
    cursor: pointer;

    position: relative;

    &:hover {
        border: 2px solid #6A40BC;
        background: #6A40BC !important;
        height: 50px;
        width: 405px;
        border-radius: 50px;
        color: #ffff;
    }

    p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: Inter;
        font-size: 18px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.015em;
        text-align: center;
        color: #ffff;
       
    }
}

.forget-password {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 13.8238px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #625BF7;
}