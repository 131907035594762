

.rcw-widget-container {
  margin: 0 60px 70px 0px !important;
}

.rcw-launcher {
  width: 110px !important;
  height: 86px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #ffffff !important;
  border-radius: 30px !important;
  box-shadow: none !important;

  @media (max-width: 991px) {
    width: 100px !important;
    height: 80px !important;
    border-radius: 26px !important;
  }
}


.companies-section1 {
  .rcw-widget-container {
    margin: 0 !important;
    right: 50px;
    bottom: -80px;
  }

  .rcw-launcher {
    border: 1px solid #9ea2b1;
  }
}

.researchers-section1 {
  .rcw-widget-container {
    margin: 0 !important;
    right: 30px;
    bottom: -50px;
  }

  .rcw-launcher {
    border: 1px solid #9ea2b1;
  }
}


.rcw-picker-btn {
  display: none;
}

.rcw-widget-container {
  position: absolute !important;

}

@media screen and (max-width: 801px) {
  .rcw-widget-container {
    left: -20px !important;
    top: 25px !important;
    bottom: 0 !important;
    right: 0 !important;
  }
}

.rcw-conversation-container {
  box-shadow: none !important;
  min-width: 500px !important;
  // left: 59px;
  // bottom: 20px;
  .rcw-messages-container{
    max-height: 50vh !important;
    min-height: 50vh !important;
  }
  .rcw-header {
    background-color: $primary !important;

    span {
      width: 90%;
      margin: auto;
      text-align: left;
    }
  }

  .rcw-sender {
    display: flex;
    align-items: center;
    background-color: #6a40bc !important;
    cursor: pointer;
    .rcw-new-message {
      width: calc(100% - 45px) !important;

      .rcw-input {
        &::-webkit-input-placeholder {
          font-size: 14px;
          color: #9ea2b1 !important;
        }
        &::-moz-placeholder {
          font-size: 14px;
          color: #9ea2b1 !important;
        }
        &:-ms-input-placeholder {
          font-size: 14px;
          color: #9ea2b1 !important;
        }
        &:-moz-placeholder {
          font-size: 14px;
          color: #9ea2b1 !important;
        }
      }
    }

    .rcw-send-icon {
      padding-left: 5px;
      height: 26px !important;
    }
  }
}

.rcw-close-launcher {
  width: 42px !important;
}

.rcw-message-text {
  max-width: 280px !important;
}

.rcw-client {
  .rcw-message-text {
    background-color: #D7A2FF !important;
    color: #ffffff !important;
  }
}

