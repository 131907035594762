// Notification Dropdown
.notification-dropdown-item {
  &.notification-dropdown-item-unread {
    .unread-point {
      position: absolute;
      top: 3px;
      left: 1px;
      width: 8px;
      height: 8px;
      background: rgba(1, 223, 184, 0.4);
      border: 1px solid #01DFB8;
      border-radius: 8px;
    }
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    line-height: 32px;
  }
  &:hover{
    background: transparent !important;
    
    .title {
      color: $primary !important; 
    }
  }
}
@each $name, $value in $colors {
  .notification-dropdown-item-#{$name} {
    color: #{$value} !important;
    .icon {
      background-color: rgba($value, 0.1) !important;
    }
  }
}


// Notification Page
.notification-item {
  border-radius: 10px !important;

  &.notification-item-unread {
    background-color: rgba(106, 64, 188, .1) !important;

    &:before {
      content: "";
    width: 15px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $primary !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

    
    }
  }
}


.notifications-wrapper {
  width: auto !important;
  max-width: 500px !important;

  .notification {
    display: flex !important;
    align-items: center !important;
    min-height: 60px !important;
    min-width: 400px !important;
    padding-left: 60px !important;
    border-top: 0 !important;
    background-color: #fdfdfd !important;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2) !important;
    font-size: 14px !important;
    color: #161c2d;

    &:before {
      content: "";
      width: 36px;
      height: 36px;
      position: absolute;
      transform: translate(-50%, 0);
      left: 30px;
    }

    .notification-message {
      font-weight: 600;
    }
  }

  .notifications-bc {
    width: auto !important;
    max-width: 500px !important;
    bottom: 10px !important;
    left: 46% !important;
  }

  .notification-error {
    border-left: 4px solid $danger;

    &:before {
      -webkit-mask: url("../images/cross-circle.svg") no-repeat 50% 50%;
      mask: url("../images/cross-circle.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background-color: $danger;
    }
  }

  .notification-success {
    border-left: 4px solid $success;

    &:before {
      -webkit-mask: url("../images/check-circle.svg") no-repeat 50% 50%;
      mask: url("../images/check-circle.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background-color: $success;
    }
  }

  .notification-info {
    border-left: 4px solid $info;

    &:before {
      -webkit-mask: url("../images/info-circle.svg") no-repeat 50% 50%;
      mask: url("../images/info-circle.svg") no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background-color: $info;
    }
  }

  .notification-dismiss {
    background-color: transparent !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #878787 !important;
  }
}
