//
// menu.scss
//
#topnav {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: transparent;
  border: 0;
  transition: all 0.5s ease;
  padding: 30px 0px;

  li {
    list-style-type: none;
  }

  .nav-item {
    a {
      &:hover {
        color: #e240f8 !important;
      }
    }
  }

  .navbar-toggler {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .dropdown-menu {
    min-width: 6rem;
    left: -30px !important;
  }

  .topnav-login-button {
    width: 146px;
    height: 50px;
    background-image: url("../images/topbar/topbar-background.svg");
    background-repeat: no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }

  .topnav-login-button-inner {
    background-image: url("../images/topbar/topbar-background-inner.svg") !important;
  }

  .navbar-mobile {
    background-color: #040425;

    @media (min-width: 1200px) {
      display: none !important;
    }
  }

  @media (max-width: 1300px) {
    .nav-item {
      .nv-link {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1199.95px) {
    background-image: url("../images/topbar/topbar-mobile-bg.svg") !important;

    
    .navbar-web {
      display: none !important;
    }
    .navbar-toggler {
      margin-left: auto;
    }
  }

  @media (max-width: 850px) {
    position: relative;
    background-image: url("../images/topbar/topbar-mobile-bg.svg") !important;

    background-color: #040425;
    margin-bottom: -60px;

    .topbar-right {
      width: 100%;
      justify-content: space-around;
    }
  }
}
