.sign-up-container {
    background-image: url("../images/login/login_bg.svg");
    width: 100%;
    height: 1080px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .signup-card {
       max-height: 747px;
       min-height: 747px;
        width: 100%;
        border-radius: 20px;

        .signup-image {
            background: url('../images/login/sign-up.svg');
            height: 750px;
            width: 100%;
            background-repeat: no-repeat;
            position: relative;
            left: 43.5rem;
        
            bottom: 0.1rem;
            border-radius: 0px 20px 20px 0px;

        }
        @media screen and (max-width: 1160px) {
            .signup-image {
              display: none !important;
            }
          }
         
    }

}

.signup-form-container {
    padding-left: 11rem;
    padding-top: 5rem;
    position: absolute !important;    
    .signup-form-title {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
        padding-bottom: 0.5rem;

        display: flex;
        align-items: center;

        color: #333333;
    }

    .signup-form-subtitle {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        padding-bottom: 1.5rem;
        color: #757575;
    }

    .signup-form-input {

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 13.8238px;
        gap: 13.82px;
        width: 405px;
        height: 36px;
        border: 1.15198px solid #DEDEDE;
        border-radius: 50px;
    }
    @media screen and (max-width: 1160px) {
        .signup-form-input {

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px 13.8238px;
            gap: 13.82px;
            width: 405px;
            height: 36px;
            border: 1.15198px solid #DEDEDE;
            border-radius: 50px;
        }
      }

    .signup-input-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */

        display: flex;
        align-items: center;

        color: #333333;
    }

    .signup-submit-button {
        height: 50px;
        width: 405px;
        border-radius: 50px;
        background: #01dfb8;
        border: 2px solid #01dfb8;
        cursor: pointer;

        position: relative;

        &:hover {
            border: 2px solid #6A40BC;
            background: #6A40BC !important;
            height: 50px;
            width: 405px;
            border-radius: 50px;
            color: #ffff;
        }

        p {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: Inter;
            font-size: 18px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: -0.015em;
            text-align: center;
            color: #ffff;
        }
    }

    .signup-have-account {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        padding-left: 8rem;
        align-items: center;
        text-decoration-line: underline;

        color: #757575;
    }
}