//
// general.scss
//

body {
  font-family: $font-family-primary;
  overflow-x: hidden !important;
  font-size: $font-size-base;
  color: $content;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $font-family-primary;
  line-height: 1.1;
  font-weight: 600;
}

::selection {
  background: rgba($primary, 0.9);
  color: $white;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

.font-primary {
  font-family: "Inter", sans-serif;
}

.font-secondary {
  font-family: "Ubuntu", sans-serif;
}

.font-tertiary {
  font-family: "Roboto", sans-serif;
}

.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-24 {
  font-size: 24px;
}

.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}

.letter-spacing-02 {
  letter-spacing: 0.2em;
}

.line-height-20 {
  line-height: 20px;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-24 {
  line-height: 24px;
}
.line-height-26 {
  line-height: 26px;
}
.line-height-28 {
  line-height: 28px;
}
.line-height-30 {
  line-height: 30px;
}
.line-height-32 {
  line-height: 32px;
}
.line-height-34 {
  line-height: 34px;
}
.line-height-36 {
  line-height: 36px;
}
.line-height-38 {
  line-height: 38px;
}
.line-height-40 {
  line-height: 40px;
}
