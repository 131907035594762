.banner-inner {
  width: 100%;
  height: 516px;
  background-image: url("../images/banner/banner-inner.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  .content {
    position: absolute;
    left: 0;
    bottom: 50px;
  }

  h1 {
    text-shadow: 0px 15.2217px 15.2217px rgba(0, 0, 0, 0.25) !important;
  }

  h5 {
    text-shadow: 0px 15.2217px 15.2217px rgba(0, 0, 0, 0.25) !important;
  }
}
