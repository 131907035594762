// Font
@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

// Bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// CSS
@import './assets/scss/style.scss';

